<template>
    <div class="about-content">
        <v-container id="intro" class="cyan text-center white--text mb-4" fluid>
            <v-row class="justify-center" no-gutters>
                <v-col cols="12" md="6">
                    <h2 class="font-family-body font-weight-regular"  :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : 'title'">
                        Über mich
                    </h2>
                    <h1 class="mb-5"  :class="$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-3'">Wer ist die WEBMACHERIN?</h1>
                </v-col>
            </v-row>
        </v-container>
        <v-container>
            <v-row>
                <v-col cols="12" sm="4">
                    <v-card class="h-100" color="orange" elevation="0" tile>
                        <v-fade-transition>
                            <v-img
                            alt="Portrait Sara"
                            :src="require('@/assets/images/team/' + selectedPortrait)"
                            height="100%"
                            ></v-img>
                        </v-fade-transition>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="8">
                    <v-card class="patterned d-flex align-center h-100" color="orange" elevation="0" tile>
                        <v-responsive class="align-center">
                            <v-card-text class="white--text text-large pa-10">
                                <p>
                                    Mein Name ist Sara Geller. 2020 habe ich mich in die Selbstständigkeit als <strong>Webdesignerin</strong> gewagt.
                                        Und seit 2021 gebe ich mein Wissen auch als <strong>WIFI-Trainerin für Frontend Development</strong> weiter.
                                </p>
                                <p>
                                    Angefangen hat alles mit dem Studium Intermedia Bachelor & Master an der Fachhochschule Vorarlberg in Dornbirn, Österreich.
                                        Danach habe ich 6 Jahre Berufserfahrung als Frontend Developer bei einer Vorarlberger Webagentur gesammelt.
                                </p>
                                <p>
                                    Wir haben vor allem mittlere und große Webshops umgesetzt, spezialisiert auf individuelle Lösungen.
                                        Ich selbst habe auch viele Business-Websites für lokale Unternehmen und Kulturinstitutionen implementiert.
                                        Durch meine Position als Schnittstelle zwischen den Entwicklern und den Anwendern habe ich nicht nur Erfahrung mit vielen digitalen Werkzeugen gewonnen,
                                        sondern kann dir die digitale Welt verständlich erklären und deinen Bedürfnissen entsprechend kompetent beraten.
                                </p>
                            </v-card-text>
                        </v-responsive>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card class="patterned d-flex h-100" color="lime darken-1" elevation="0" tile>
                        <v-responsive>
                            <v-card-text class="white--text pa-10">
                                <h2 class="display-1">
                                    Meine Zertifizierungen
                                </h2>
                            </v-card-text>
                        </v-responsive>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-card class="d-flex flex-column h-100" color="lime darken-1" elevation="0" tile>
                        <v-card-text class="white--text pa-10">
                            <h3 class="mb-5">CESE<br>Certified Ecommerce & Social Media Expertin</h3>
                            <v-dialog v-model="ceseModal" width="1200px">
                                <template v-slot:activator="{ on }">
                                    <v-img
                                        alt="CESE - Certified Ecommerce & Social Media Expertin"
                                        class="mb-3 img-left img-link"
                                        contain
                                        max-height="110"
                                        :src="require('@/assets/images/certificates/zertifizierung_cese.png')"
                                        v-on="on"
                                    ></v-img>
                                </template>

                                <v-card>
                                    <v-card-title>
                                        <p>CESE - Certified Ecommerce & Social Media Expertin</p>
                                        <v-spacer></v-spacer>
                                        <v-btn @click="ceseModal = false" icon>
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-img
                                            alt="Zertifikat - CESE - Certified Ecommerce & Social Media Expertin"
                                            contain
                                            max-width="1200"
                                            :src="require('@/assets/images/certificates/CESE-Zertifikat_de.webp')"
                                        ></v-img>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn @click="ceseModal = false" color="primary" class="mb-5 mr-4" x-large>
                                            Schließen
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <p>
                                Ich bin zertifiziert als <strong>Certified Ecommerce & Social Media Expertin</strong> nach den Kriterien der ÖNORM EN ISO/IEC 17024.
                                    Zertifiaktshalter:innen besitzen ein umfassendes Wissen im Bereich neuer Medien.
                                    Sie sind in der Lage Marketingmaßnahmen in Abstimmung mit ihren Kunden in sozialen Medien zu konzipieren,
                                    zu planen und zu beauftragen sowie eine Erfolgskontrolle durchzuführen.
                            </p>
                        </v-card-text>
                        <v-spacer></v-spacer>
                        <v-card-actions class="px-10 pb-10">
                            <v-btn
                                color="secondary"
                                block
                                href="https://zertifizierung.wifi.at/zertifizierungwifiat/personenzertifkate/dienstleistung/certified-e-commerce-and-social-media-expert/zertifizierung_ecommerce_expert"
                                target="blank"
                            >
                                Mehr Infos
                                <v-icon dark right>mdi-launch</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-card class="d-flex flex-column h-100" color="lime darken-1" elevation="0" tile>
                        <v-card-text class="white--text pa-10">
                            <h3 class="mb-5">WIFI Österreich<br>Trainerin-Diplom</h3>
                            <v-dialog v-model="trainerinModal" width="1200px">
                                <template v-slot:activator="{ on }">
                                    <v-img
                                        alt="WIFI Österreich Trainerin-Diplom"
                                        class="mb-3 img-left img-link"
                                        contain
                                        max-height="110"
                                        :src="require('@/assets/images/certificates/wifi-oesterreich-trainerin-diplom.svg')"
                                        v-on="on"
                                    ></v-img>
                                </template>

                                <v-card>
                                    <v-card-title>
                                        <p>WIFI Österreich Trainerin-Diplom</p>
                                        <v-spacer></v-spacer>
                                        <v-btn @click="trainerinModal = false" icon>
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-img
                                            alt="WIFI Österreich Trainerin-Diplom"
                                            contain
                                            max-width="1200"
                                            :src="require('@/assets/images/certificates/WIFI-Oesterreich-Trainerin-Diplom.webp')"
                                        ></v-img>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn @click="trainerinModal = false" color="primary" class="mb-5 mr-4" x-large>
                                            Schließen
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <p>
                                Mit meiner Ausbildung zur Trainerin für Erwachsenenbildung, weiß ich wie man Menschen das Lernen ermöglicht.
                                    Wer lernen will, muss selbst aktiv werden. Deshalb brauchen Lernende zuallererst die Fähigkeit zum Lernen - und den Raum dazu.
                                    Trainer/innen in der Erwachsenenbildung entwickeln diese Selbstlernkompetenz.
                                    Sie wissen wie man Menschen aktiviert, anregt und ihre Kompetenzen stärkt.
                            </p>
                        </v-card-text>
                        <v-spacer></v-spacer>
                        <v-card-actions class="px-10 pb-10">
                            <v-btn
                                color="secondary"
                                block
                                href="https://www.wifi.at/kursbuch/persoenlichkeit/trainerausbildung/trainer-erwachsenenbildung/trainer-erwachsenenausbildung"
                                target="blank"
                            >
                                Mehr Infos
                                <v-icon dark right>mdi-launch</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <v-card class="d-flex flex-column h-100" color="lime darken-1" elevation="0" tile>
                        <v-card-text class="white--text pa-10">
                            <h3 class="mb-5">Marketingkooperationspartnerin<br>von Tourismus Montafon</h3>
                            <v-dialog v-model="montafonModal" width="1200px">
                                <template v-slot:activator="{ on }">
                                    <v-img
                                        alt="Marketingkooperationspartnerin von Tourismus Montafon"
                                        class="mb-3 img-left img-link"
                                        contain
                                        max-height="110"
                                        :src="require('@/assets/images/team/partner-tourismus-montafon_rot.svg')"
                                        v-on="on"
                                    ></v-img>
                                </template>

                                <v-card>
                                    <v-card-title>
                                        <p>Marketingkooperationspartnerin von Tourismus Montafon</p>
                                        <v-spacer></v-spacer>
                                        <v-btn @click="montafonModal = false" icon>
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-img
                                            alt="Urkunde Marketingkooperationspartnerin von Tourismus Montafon"
                                            contain
                                            max-width="1200"
                                            :src="require('@/assets/images/certificates/partnerurkunde-tourismus-montafon.webp')"
                                        ></v-img>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn @click="montafonModal = false" color="primary" class="mb-5 mr-4" x-large>
                                            Schließen
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <p>
                                Ich bin eine <strong>Marketingkooperationspartnerin von Tourismus Montafon</strong>.
                                    Das bedeutet ich wurde von Montafon Tourismus geschult und weiß worauf es bei der „Marke Montafon“ ankommt.
                            </p>
                        </v-card-text>
                        <v-spacer></v-spacer>
                        <v-card-actions class="px-10 pb-10">
                            <v-btn
                                color="secondary"
                                block
                                href="https://www.montafon.at/"
                                target="blank"
                            >
                                Mehr Infos
                                <v-icon dark right>mdi-launch</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="mb-7">
                <v-col cols="12" md="6" xl="8">
                    <v-row>
                        <v-col cols="12" sm="7" md="12" xl="7">
                            <v-row>
                                <v-col cols="12">
                                    
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="text-center">
                    <h1 class="display-3 mb-5">Partner</h1>
                </v-col>
            </v-row>
            <v-row align="stretch">
                <v-col
                    v-for="(partner ,i) in partners"
                    :key="i"
                    cols="12"
                    md="6"
                    lg="4"
                    xl="3"
                >
                    <v-card class="d-flex flex-column mb-5" height="100%">
                        <v-img
                            :alt="partner.title"
                            :aspect-ratio="3/2"
                            :contain="partner.contain ? true : false"
                            max-height="230"
                            :src="require('@/assets/images/team/' + partner.image)"
                        />
                        <v-card-title>{{partner.title}}</v-card-title>
                        <v-card-text class="pb-0" v-html="partner.text"></v-card-text>
                        <v-spacer></v-spacer>
                        <v-card-actions>
                            <v-btn
                                color="secondary"
                                block
                                :href="partner.link"
                                target="blank"
                                rel="noopener"
                            >
                                Website besuchen
                                <v-icon dark right>mdi-launch</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<style lang="scss">

</style>

<script>

export default {
    name: "About",
    methods: {
        randomPortrait (items) {
            return items[Math.floor(Math.random()*items.length)];
        }
    },
    created() {
        this.selectedPortrait = this.randomPortrait(this.portraits);

        /*let self = this
        setInterval(() =>{ 
            self.selectedPortrait = self.randomPortrait(self.portraits);
        }, 1000);*/
    },
    data: () => ({
        ceseModal: false,
        trainerinModal: false,
        montafonModal: false,
        portraits:   [
            /*'portrait-sara-katze.webp',
            'portrait-sara-laptop.jpg',
            'portrait-sara-neutral.jpg',*/
            'portrait-sara-lachend.webp',
        ],
        selectedPortrait: null,
        partners:  [
            {
                title: 'BNI Vorarlberg',
                text: `
                    <p>Das weltweit führende Netzwerk für Kontakte, Empfehlungen und Umsätze.</p>
                    <p>Die Mission von BNI besteht darin, Unternehmern zu helfen, ihr Unternehmen über strukturiertes, positives und professionelles Empfehlungsmarketing zu vergrößern, das ihnen ermöglicht, wichtige und langfristige Beziehungen mit den besten Unternehmerkollegen aufzubauen.</p>
                    <p>Teilnehmer am BNI-Marketingprogramm zu sein, bedeutet vor allem, Unterstützung beim Vermarkten der eigenen Dienstleistung oder der eigenen Produkte zu haben. Dafür bezahlen Sie weder Gehälter noch Provisionen. BNI funktioniert nach dem Grundsatz: „Wer gibt, gewinnt!“.</p>
                `,
                link: 'https://bni-vorarlberg.at/',
                image: 'partner-bni.jpg'
            },
            {
                title: 'GWÖ Österreich',
                text: `
                    <p>Die Gemeinwohl-Ökonomie ist ein Wirtschaftsmodell, dessen Ziel ein gutes Leben für alle auf einem gesunden Planeten ist.</p>
                    <p>Im Mittelpunkt steht der Gedanke, dass werteorientierte Unternehmen auf das Gemeinwohl achten und sich aktiv dafür einsetzen:</p>
                    <ul class="mb-3">
                        <li>Menschenwürde</li>
                        <li>Solidarität und soziale Gerechtigkeit</li>
                        <li>Ökologische Nachhaltigkeit</li>
                        <li>Transparenz und Mitbestimmung</li>
                    </ul>
                    <p>Gemeinwohlorientiere Unternehmen sollen in einer ethisch orientierten Wirtschaftswelt bessere Rahmenbedingungen erhalten.</p>
                `,
                link: 'https://austria.ecogood.org/',
                image: 'partner-gwo.webp',
                contain: 'true'
            },
            {
                title: 'wagnercommunications gmbh - agentur für kreatives marketing',
                text: `
                    <p><strong>wagner</strong>communications macht als Marketing- und Werbeagentur Sinn. Für kreatives Marketing, Querdenken und Innovation. Für Corporate Branding, Corporate Design und Storytelling. Für einzigartige Grafik, Web- und Digitallösungen. Alles zusammen oder jedes für sich.</p>
                    <p>Das tun wir für Menschen, Unternehmen und Marken. Mit all jenen, die auf der Suche sind nach einfacher, klarer, verständlicher Kommunikation und wirkungsvoller Werbung.</p>
                    <p>Dafür stehen wir. Das bieten wir. Mit Herz und Verstand.<br>
                    Mit unternehmerischer Intelligenz. Aus Leidenschaft und aus Erfahrung.</p>
                    <p>#versprochen.</p>
                `,
                link: 'https://www.wagner.li/',
                image: 'partner-wagner-oscar.jpg'
            },
            {
                title: 'teamwork werbeagentur',
                text: `
                    <p>Seit mehr als 20 Jahren sind wir Komplettanbieter wenn es darum geht, ihr Unternehmen erfolgreich und ehrlich zu präsentieren. Vom unverbindlichem Erstgespräch bis zum aussagekräftigen Erscheinungsbild ihres Unternehmens, erstellen wir ihr individuelles Werbepaket. Mit unseren Spezialisten in den Bereichen Fotografie, Social Media, Webseiten, Text und SEO erzählen wir Ihre persönliche Erfolgsgeschichte.</p>
                    <p>Wir machen Konzepte, koordinieren die Produktion, kreieren Marken und organisieren Events. Aber nicht nur weil wir es können und gerne tun. Wir arbeiten mit Leidenschaft für unsere Kunden und aus Liebe zu großartigen Ideen und deren Umsetzung. Bodenständig, für Menschen und deren Bedürfnisse. Oder man könnte auch sagen, mit gesundem Werbeverstand.</p>
                `,
                link: 'https://www.teamwork-werbung.at/',
                image: 'partner-teamwork.webp'
            },
            {
                title: 'Ländlekassa - Registrierkasse mit Webshop-Anbindung',
                text: `
                    <p>Ländle-Kassa ist eine umfangreiche Kassa-Software, die Sie mit Ihrem Funktionsumfang wie auch durchdachten Ideen begeistern wird.</p>
                    <p>Ohne viel Einarbeitung werden Sie in kurzer Zeit Ihre Kassa voll im Griff haben, Ihre Mitarbeiter freuen sich über die leichte Handhabung und Ihr Geschäft kann durch zahlreiche Zusatzfunktionen angekurbelt werden.</p>
                    <p>Klingt gut? - Ist es auch.</p>
                `,
                link: 'https://www.laendlekassa.at/',
                image: 'partner-laendlekassa.webp'
            },
            {
                title: 'BREAK. Media Solutions GmbH - Online-Agentur',
                text: `
                    <p>Wir sind eine Online-Agentur mit Sitz in Vorarlberg. Seit unserer Gründung im Jahr 2003 sind wir auf die Entwicklung komplexer eCommerce-Projekte und Business Websites spezialisert. Websites, die nicht nur schöner Schein sind, sondern Ihren Zielgruppen das rüberbringen, was Sie zu sagen haben. Und Shop-Lösungen, die Ihre Erwartungen an Performance und Umsatz auch erfüllen.</p>
                    <p>Als Full-Service Online-Agentur wickeln wir alle Dienstleistungen wie Konzeption, Design, Programmierung, Online-Marketing und Support in-House ab.</p>
                `,
                link: 'https://www.break-media.at/',
                image: 'partner-break.webp'
            },
        ]
    })
};
</script>
